import { css } from "@emotion/react";
import { Button, LinearProgress, Box } from "@mui/material";
import { parse } from "csv/dist/esm/sync";
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { importContractCsv, sequentialPromiseAll } from "./utils";
import { CsvModel, LocalizedValueLabels } from "./forDirect/types";
import { HttpStatus } from "../../../models/HttpStatus";
import { AdminCheck } from "../AdminCheck";  
import styled from "@emotion/styled";
import { FixedSizeList, ListChildComponentProps, VariableSizeList } from "react-window";


export const ImportContract = () => {

  const [importedIds, setImportedIds] = useState<Array<string | undefined>>([]);
  const [skipedIds, setSkipedIds] = useState<Array<string | undefined>>([]);
  const [isImportError, setIsImportError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [errorDetails, setErrorDetails] = useState<{key: string, values: string[]}[][]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [csvLines, setCsvLines] = useState(0);

  const fileRef = useRef<HTMLInputElement>(null);

  const importContract = async (id: string, file: RefObject<HTMLInputElement>) => {

    const csvs = file.current?.files;
    if (csvs === undefined || csvs === null) {
      return;
    }

    const buffer = Buffer.from(await csvs[0].arrayBuffer())

    // CSVファイル全体をrecordsに取り込む
    const records: string[][] = parse(buffer, {
      bom: true,
      columns: false,
      relax_column_count: true,
    });

    // CSVのヘッダ有無のチェック
    const headerLine = records.shift()
    if (!headerLine?.length) {
      setIsImportError(true);
      setErrorMessages(['CSVが空のため、処理を中断しました。']);
      return;
    }

    // ヘッダ行のカラム数と各行のカラムの数を取得
    const expectedColumnCount = headerLine.length;
    const lineColumns = records.map((line) => line.length);

    if (lineColumns.length === 0) {
      setIsImportError(true);
      setErrorMessages(['取り込めるデータがありません。']);
      return;
    }

    // CSVデータの読み込み
    const lines: CsvModel[] = parse(buffer, { bom: true, columns: true, relax_column_count: true });
    setCsvLines(lines.length);

    await sequentialPromiseAll(lines.map((line, index) => async () => {
      const lineNumber = 1 + index;

      if (expectedColumnCount !== lineColumns[index]) {
        setErrorMessages(prev => [...prev, `${lineNumber}行目`]);
        setErrorDetails(prev => [...prev, [{key: 'カラム数の不一致', values: [`期待されるカラム数は${expectedColumnCount}ですが、実際は${lineColumns[index]}です。`]}]]);
        return;
      }

      const { id, result, message, errors, httpStatus } = await importContractCsv(line);
      if (result === 'success') {
        const createType = httpStatus?.status === HttpStatus.CREATED ? '新規' : '更新';
        setImportedIds(prev => [`${createType}: ${id}`, ...prev]);
      } else if (result === 'skip') {
        setSkipedIds(prev => [`スキップ: ${lineNumber}行目: ${id}`, ...prev]);
      } else if (result === 'failure') {
        setErrorMessages(prev => [...prev, `${lineNumber}行目`]);
        setErrorDetails(prev => [...prev, errors ?? []]);
      } else { // result === 'error'
        setErrorMessages(prev => [...prev, `${lineNumber}行目: ${message}`]);
        setErrorDetails(prev => [...prev, errors ?? []]);
      }
    }))
  }

  const onChangeInputFile = useCallback(() => {
    if ((fileRef?.current?.files?.length || 0) > 0) {
      setSelectedFile(fileRef?.current?.files?.[0]);
    } else {
      setSelectedFile(undefined);
      setCsvLines(0);
    }
  }, []);

  const importContracts = useCallback(async () => {
    setIsImporting(true);
    setIsImportError(false);
    setImportedIds([]);
    setSkipedIds([]);
    setErrorMessages([]);
    setErrorDetails([]);
    await importContract('', fileRef);
    setIsImporting(false);
  }, [importContract]);

  const ImportedListItem = ({ index, style }: ListChildComponentProps) => (<StyledListItem style={style}>{importedIds[index]}</StyledListItem>);
  const SkipedListItem = ({ index, style }: ListChildComponentProps) => (<StyledListItem style={style}>{skipedIds[index]}</StyledListItem>);
  const ErrorListItem = ({ index, style }: ListChildComponentProps) => (<StyledListItem style={style}>{errorMessages[index]}</StyledListItem>);
  
  const ErrorDetailListItem = ({ index, style }: ListChildComponentProps) => {
    const line = errorMessages[index];

    return (
      <ul style={style}>
        <StyledErrorList>{line}</StyledErrorList>
        <ul>
          {errorDetails[index].map((errorDetail, index) => (
            <StyledErrorList key={index}>
              {errorDetail.key}
              <ul>
                {errorDetail.values.map((value, index) => (
                  <StyledErrorList key={index}>{value}</StyledErrorList>
                ))}
              </ul>
            </StyledErrorList>
          ))}
        </ul>
      </ul>    
    );
  };

  const errorDetailItemHeight = (index: number) => errorDetails[index].length * 30 + 30;

  return (
    <div css={css({ margin: "2rem" })} >
      <AdminCheck />
      <h2>情報編集 契約インポート</h2>
      <div>
        <StyledFileDiv>
          <Button variant={selectedFile ? 'outlined' : 'contained'} component="label" startIcon={<FontAwesomeIcon icon={faFileCsv} />} disabled={isImporting} >
            CSVファイルを選択
            <input type="file" hidden ref={fileRef} accept="text/csv" onChange={onChangeInputFile} />
          </Button>
          <StyledSelectedFileText>{selectedFile?.name}</StyledSelectedFileText>
          <Box sx={{ width: '50%' }} style={{ visibility: csvLines && !isImportError ? 'visible' : 'hidden' }}>
            <LinearProgress variant="determinate" value={csvLines ? ((importedIds.length + skipedIds.length + errorMessages.length) / csvLines) * 100 : 0} />
          </Box>
        </StyledFileDiv>
        <StyledImportButtonDiv style={{ visibility: selectedFile ? 'visible' : 'hidden' }}>
          <Button variant="contained" onClick={importContracts} disabled={isImporting}>{isImporting ? '取り込み中...' : '取り込み'}</Button>
        </StyledImportButtonDiv>
      </div>
      {isImportError ? (
        <div>
          <h3>インポート失敗</h3>
          <StyledFixedSizeList height={300} width="50%" itemSize={30} itemCount={errorMessages.length}>
            {ErrorListItem}
          </StyledFixedSizeList>
        </div>
      ) : errorMessages.length > 0 && errorMessages.length === errorDetails.length ? (
        <div>
          <h3>インポートに失敗した契約ID</h3>
          <StyledErrorDetailList height={300} width="50%" itemSize={errorDetailItemHeight} itemCount={errorMessages.length}>
            {ErrorDetailListItem}
          </StyledErrorDetailList>
        </div>
      ) : null}
      <div>
        {importedIds.length > 0 &&
          <>
            <h3>インポートをスキップした契約ID</h3>
            <StyledFixedSizeList height={300} width="50%" itemSize={30} itemCount={skipedIds.length}>
              {SkipedListItem}
            </StyledFixedSizeList>
          </>
        }
      </div>
      <div>
        {importedIds.length > 0 &&
          <>
            <h3>インポートに成功した契約ID</h3>
            <StyledFixedSizeList height={300} width="50%" itemSize={30} itemCount={importedIds.length}>
              {ImportedListItem}
            </StyledFixedSizeList>
          </>
        }
      </div>
    </div>
  )
}

const StyledFixedSizeList = styled(FixedSizeList)`
  background-color: #fcfcfc;
`;

const StyledListItem = styled.div`
  display: flex;
  padding-left: .5em;
  align-items: center;
  white-space: nowrap;
`;

const StyledErrorDetailList = styled(VariableSizeList)`
  background-color: #fcfcfc;
`;

const StyledFileDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledSelectedFileText = styled.div`
  margin-top: .5em;
  font-size: 14px;
  height: 20px;
`;

const StyledImportButtonDiv = styled.div`
  margin-top: 1em;
`;

const StyledErrorList = styled.li({
  fontSize: '0.8rem',
});

