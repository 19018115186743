/**
 * 指定された値が `null` または `undefined` であるかを判定します。
 *
 * この関数は、値が `null` または `undefined` の場合に `true` を返します。
 * 有効な値（例: 数値、文字列、オブジェクト、空文字列など）と
 * `null` または `undefined` を区別したい場合に便利です。
 *
 * @template T - 判定対象の値の型。
 * @param {T | undefined | null} target - 判定する値。
 * @returns {boolean} 値が `null` または `undefined` の場合は `true`、それ以外の場合は `false`。
 *
 * @example
 * // 使用例
 * isNullOrUndefined(null); // true
 * isNullOrUndefined(undefined); // true
 * isNullOrUndefined(0); // false
 * isNullOrUndefined(''); // false
 * isNullOrUndefined([]); // false
 */
export const isNullOrUndefined = <T>(target: T | undefined | null) => target === undefined || target === null;

/**
 * 指定された値が `null` または `undefined` かどうかを判定します。
 *
 * このユーティリティ関数は、値が `null` または `undefined` の場合に `false` を返します。
 * 有効な値（例: `0`, `false`, 空文字列など）と、`null` または `undefined` を区別したい場合に便利です。
 *
 * @template T - 判定する値の型。
 * @param {T | undefined | null} target - 判定対象の値。
 * @returns {boolean} 値が `null` または `undefined` の場合は `false`、それ以外の場合は `true`。
 *
 * @example
 * // 基本的な使用例
 * isNotNullOrUndefined(null); // false
 * isNotNullOrUndefined(undefined); // false
 * isNotNullOrUndefined(0); // true
 * isNotNullOrUndefined(''); // true
 */
export const isNotNullOrUndefined = <T>(target: T | undefined | null) => !isNullOrUndefined(target)