import styled from "@emotion/styled";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Modal } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useCallback, useState } from "react";
import { StyledPanelContainer } from "./styles";

export const CouponPanel = () => {
  const COUPON_PAGE_URL = `${process.env.REACT_APP_COUPON_PAGE_URL}?token=${process.env.REACT_APP_COUPON_PAGE_TOKEN}`;

  const [isActive, setIsActive] = useState(false);
  const openModal = () => {
    setIsActive(true);
  };
  const closeModal = () => {
    setIsActive(false);
  };
  
  return (
    <>
      <StyledPanelContainer>
        <Grid container style={{flexWrap: 'nowrap'}}>
          <StyleGrid size="fit-content" margin="0 10px 0 0">
            <StyleBunner src={require("../../../images/coupon-page-bunner.png").default} alt="Coupon Banner"/>
          </StyleGrid>  
          <StyleGrid size="20%" align="stratch">
            <LinkButton title="クーポンページを開く" text='PCで見る' url={COUPON_PAGE_URL} />
            <span></span>
            <StyledButton title='QRコードを表示' onClick={openModal}>
              スマホで見る
            </StyledButton>
          </StyleGrid>
        </Grid>
      </StyledPanelContainer>
      <StyledModal
        open={isActive}
        onBackdropClick={closeModal}
        tabIndex={-1}>
        <StyledModalContainer>
          <StyledQRCode>
            <QRCodeSVG value={COUPON_PAGE_URL} size={200}/>
          </StyledQRCode>
          <StyledButton style={{width: 'fit-content'}} onClick={closeModal}>
            閉じる
          </StyledButton>
        </StyledModalContainer>
      </StyledModal>
    </>
  )
}

const StyleGrid = styled(Grid)<{ size?: string, margin?: string, align?: string }>`
  width: ${({ size }) => size || '100%'};
  display: grid;
  align-content: ${({ align }) => align || 'center'};
  margin: ${({ margin }) => margin || '0'};
`;

const StyleBunner = styled.img`
  max-width: 100%;
  width: auto;
`;

const StyledButton = styled.button`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4px;
  font-weight: bold;
  color: white;
  background: #0056b0;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  ${props => {
    let styles = '';
    let fontSize = 4;
    for (let i = 900; i <= 1800; i += 90) {
      styles += `
        @media (min-width: ${i}px) {
          font-size: ${fontSize++}px;
        }
      `;
    }
    return styles;
  }}
`;

const LinkButton = ({ title, text, url }: { title: string, text: string, url?: string }) => (
  <StyledButton title={title} onClick={() => { url && window.open(url, '_blank', 'noreferrer=yes') }}>
    {text}
    <span>&nbsp;</span>
    <FontAwesomeIcon icon={faUpRightFromSquare} color="#ffffff80" />
  </StyledButton>
)

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModalContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  text-align: -webkit-center;
`;

const StyledQRCode = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 20px;
`;
