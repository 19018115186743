import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledPanelContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 1.5rem 2rem;
  // min-height: 6rem;
`;

export const StyledPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const StyledPanelTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const StyledPanelContent = styled.div`
  margin-top: 1rem;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5em;
`;
