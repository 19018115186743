import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useState, VFC } from "react";
import { paymentMethod, depositKind, taxCategory, seasonalAdjustment, depositKindType } from "../../../constant";
import { api } from "../../../infra/Api";
import { ContractDetail as ContractDetailProps, convertToContractDetail } from "../../../models/contract/ContractDetail";
import { ContractDetailFromApi } from "../../../models/contract/ContractFromApi";
import { HttpStatus } from "../../../models/HttpStatus";
import { dateFormat, withUnitOrNone } from "../../../util/stringFormatter";
import { articleNameStyle, articleStyle, headLineStyle, headLineWrapperStyle, labelStyle, labelValueStyle, linkStyle, sectionStyle } from "./ContractDetailBasic";
import { ContractDetailEditFormType } from "./ContractDetailEditFormType";
import { ContractDetailEditModal } from "./ContractDetailEditModal";
import { useUser } from "../../..//hooks/useUser";
import { ContractDetailContext } from "../../usecase/ContractDetailComp";
import { PDContractDetailEditModal } from "./PDContractDetailEditModal";
import { getKeyByValue } from "../../../util/objectUtil";

const URL = process.env.REACT_APP_ADMIN_URL;

export const ContractDetailCondition: VFC<{ contractDetail: ContractDetailProps, am_i_admin: boolean | undefined }> = ({
  contractDetail,
  am_i_admin
}) => {

  const { reloadContract } = useContext(ContractDetailContext);

  const [contractDetailEditFormType, setContractDetailEditFormType] = useState<ContractDetailEditFormType>();
  const [_, accountUser] = useUser();

  const onCloseModal = useCallback((isUpdated: boolean) => {
    if (isUpdated) reloadContract();
    setContractDetailEditFormType(undefined);
  }, [reloadContract]);

  const readOnlyStyle = accountUser != null && (accountUser?.isAdmin || !accountUser?.isReadOnly) ? "" : "none"

  const editStyle = accountUser != null && (accountUser?.isAdmin || (accountUser?.isReadOnly == false && contractDetail.contractKind == 2)) ? "" : "none"

  const total_renewal_fee =
    (Number(contractDetail.contract.updateBaseFeeWithTax) || 0)
    + (Number(contractDetail.contract.updateManagementFeeWithTax) || 0)
    + (Number(contractDetail.contract.renewalManagementFeeWithTax) || 0);

  const showEditModal = useCallback((formType: ContractDetailEditFormType) => {
    setContractDetailEditFormType(contractDetail.contractKind === 0 ? 'park_direct' : formType);
  }, [setContractDetailEditFormType]);

  return (
    <section css={sectionStyle}>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>契約条件</h3>
        <a css={linkStyle} style={{ display: readOnlyStyle }} rel="noreferrer" onClick={() => showEditModal('payment_information')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>税区分</div><div>{contractDetail.contract.taxCategory !== null ? taxCategory[contractDetail.contract.taxCategory] : ''}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>月額賃料(税込)</div><div>{withUnitOrNone(contractDetail.contract.rentWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>月額賃料(税抜)</div><div>{withUnitOrNone(contractDetail.contract.rentWithoutTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>月額賃料(消費税額等)</div><div>{withUnitOrNone(contractDetail.contract.rentTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初月賃料(税込)</div><div>{withUnitOrNone(contractDetail.contract.firstMonthRentWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>最終月賃料(税込)</div><div>{withUnitOrNone(contractDetail.contract.finalMonthRentWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初月賃料(税抜)</div><div>{withUnitOrNone(contractDetail.contract.firstMonthRentWithoutTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>最終月賃料(税抜)</div><div>{withUnitOrNone(contractDetail.contract.finalMonthRentWithoutTax, "円")}</div></div>

        <div css={labelValueStyle}><div css={labelStyle}>月額保証／管理料(税込)</div><div>{withUnitOrNone(contractDetail.contract.monthlyGuaranteeAndManagementFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初回保証／管理料(税込)</div><div>{withUnitOrNone(contractDetail.contract.initialGuaranteeAndManagementFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初月保証／管理料(税込)</div><div>{withUnitOrNone(contractDetail.contract.firstMonthGuaranteeAndManagementFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>最終月保証／管理料(税込)</div><div>{withUnitOrNone(contractDetail.contract.finalMonthGuaranteeAndManagementFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>敷金(非課税)</div><div>{withUnitOrNone(contractDetail.contract.depositWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>礼金(税込)</div><div>{withUnitOrNone(contractDetail.contract.keyMoneyWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>事務手数料(税込)</div><div>{withUnitOrNone(contractDetail.contract.administrativeFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>仲介手数料(税込)</div><div>{withUnitOrNone(contractDetail.contract.brokerageFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>初回保証料(非課税)</div><div>{withUnitOrNone(contractDetail.contract.initialGuaranteeFeeWithoutTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>月額保証料(非課税)</div><div>{withUnitOrNone(contractDetail.contract.monthlyGuaranteeFeeWithoutTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>その他1</div><div><p>{contractDetail.contractAttribution.other1}</p><p>{contractDetail.contractAttribution.other1Memo}</p></div></div>
        <div css={labelValueStyle}><div css={labelStyle}>その他2</div><div><p>{contractDetail.contractAttribution.other2}</p><p>{contractDetail.contractAttribution.other2Memo}</p></div></div>
        <div css={labelValueStyle}><div css={labelStyle}>その他3</div><div><p>{contractDetail.contractAttribution.other3}</p><p>{contractDetail.contractAttribution.other3Memo}</p></div></div>

      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>その他費用</h3>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>更新料(税込)</div><div>{withUnitOrNone(contractDetail.contract.updateBaseFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>更新事務手数料(税込)</div><div>{withUnitOrNone(contractDetail.contract.updateManagementFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>管理更新料(税込)</div><div>{withUnitOrNone(contractDetail.contract.renewalManagementFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>更新費用合計(税込)</div><div>{withUnitOrNone(total_renewal_fee, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>保管場所使用承諾書発行手数料(税込)</div><div>{withUnitOrNone(contractDetail.contract.issuePaperFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>季節調整</div><div>{contractDetail.contract.seasonalAdjustment !== null ? seasonalAdjustment[contractDetail.contract.seasonalAdjustment] : ''}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>季節賃料FROM</div><div>{withUnitOrNone(contractDetail.contract.seasonalRentFrom, "月")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>季節賃料TO</div><div>{withUnitOrNone(contractDetail.contract.seasonalRentTo, "月")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>季節費用(税込)</div><div>{withUnitOrNone(contractDetail.contract.seasonalFeeWithTax, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>日割賃料返金(税込)</div><div>{withUnitOrNone(contractDetail.contract.proratedRentRefund, "円")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>敷金返金(非課税)</div><div>{withUnitOrNone(contractDetail.contract.securityDepositRefund, "円")}</div></div>
      </article>
      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>支払い方法</h3>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>支払先</div><div>{paymentMethod[contractDetail.contract.paymentMethod]}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>毎月支払日</div><div>{contractDetail.contractKind === 0 ? "PD請求" : withUnitOrNone(contractDetail.contract.paymentDate, "")}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>支払先口座情報</div><div>{contractDetail.paymentBank}</div></div>
      </article>

      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>初期費用支払</h3>
        <a css={linkStyle} style={{ display: editStyle }} rel="noreferrer" onClick={() => showEditModal('init_payment')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>法人名</div><div>{contractDetail.contractAttribution.initPaymentCompanyName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>送金日</div><div>{dateFormat(contractDetail.contractAttribution.initPaymentSendMoneyDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>銀行番号</div><div>{contractDetail.contractAttribution.initPaymentBankNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>銀行名</div><div>{contractDetail.contractAttribution.initPaymentBankName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>支店番号</div><div>{contractDetail.contractAttribution.initPaymentBranchStoreNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>支店名</div><div>{contractDetail.contractAttribution.initPaymentBranchName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>預金種目</div><div>{contractDetail.contractAttribution?.initPaymentDepositKindType !== null ? getKeyByValue(depositKindType, +contractDetail.contractAttribution?.initPaymentDepositKindType) : ''}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>口座番号</div><div>{contractDetail.contractAttribution.initPaymentBankAccountNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>口座名</div><div>{contractDetail.contractAttribution.initPaymentBankAccountName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>送金金額</div><div>{contractDetail.contractAttribution.initPaymentSendMoneyAmount}</div></div>

      </article>

      <div css={headLineWrapperStyle}>
        <h3 css={headLineStyle}>毎月支払</h3>
        <a css={linkStyle} style={{ display: editStyle }} rel="noreferrer" onClick={() => showEditModal('monthly_payment')}><FontAwesomeIcon icon={faPen} /> 編集する</a>
      </div>
      <article css={articleStyle}>
        <div css={labelValueStyle}><div css={labelStyle}>法人名</div><div>{contractDetail.contractAttribution.monthlyPaymentCompanyName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>送金日</div><div>{dateFormat(contractDetail.contractAttribution.monthlyPaymentSendMoneyDate)}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>銀行番号</div><div>{contractDetail.contractAttribution.monthlyPaymentBankNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>銀行名</div><div>{contractDetail.contractAttribution.monthlyPaymentBankName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>支店番号</div><div>{contractDetail.contractAttribution.monthlyPaymentBranchStoreNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>支店名</div><div>{contractDetail.contractAttribution.monthlyPaymentBranchName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>預金種目</div><div>{contractDetail.contractAttribution?.monthlyPaymentDepositKindType !== null ? getKeyByValue(depositKindType, +contractDetail.contractAttribution?.monthlyPaymentDepositKindType) : ''}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>口座番号</div><div>{contractDetail.contractAttribution.monthlyPaymentBankAccountNumber}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>口座名</div><div>{contractDetail.contractAttribution.monthlyPaymentBankAccountName}</div></div>
        <div css={labelValueStyle}><div css={labelStyle}>送金金額</div><div>{contractDetail.contractAttribution.monthlyPaymentSendMoneyAmount}</div></div>

      </article>
      {contractDetail.contractKind === 0 ? (
        <PDContractDetailEditModal
          isVisible={contractDetailEditFormType === 'park_direct'}
          isAdmin={am_i_admin}
          contractDetail={contractDetail}
          onClose={onCloseModal} />
      ) : (
        <ContractDetailEditModal
          formType={contractDetailEditFormType}
          isAdmin={am_i_admin}
          contractDetail={contractDetail}
          onClose={onCloseModal} />
      )}
    </section>
  )

}